<template>
  <v-card outlined class="mh-main-filter">
    <v-card-title
      class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
    >
      Производителям контента
    </v-card-title>
    <v-row class="ma-1 pr-2 pl-2">
      <v-col cols="6" class="pb-0 pt-0 pr-1 pl-1">
        <v-autocomplete
          class="mh-dropdown-slot"
          menu-props="openOnClick, closeOnClick, closeOnContentClick"
          :readonly="true"
          dense
          v-model="indicator"
          outlined
          :hide-details="isMobile ? false : 'auto'"
          label="показатель"
          no-data-text="Ничего не найдено"
          :items="indicatorsList"
          item-text="name"
          item-disabled="disabled"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" class="pb-0 pt-0 pr-1 pl-1">
        <main-calendar
          ref="mcalendar"
          :disabledThrough="false"
          :disabled="false"
          @calendarok="calendarHandler"
          @ok="calendarHandler"
          :with-range="true"
        ></main-calendar>
      </v-col>
    </v-row>
    <v-row class="ma-1 pr-2 pl-2 mt-2">
      <v-col cols="12" class="pb-0 pt-0 pr-1 pl-1">
        <mh-drop-down
          :items="shows"
          ref="showsSel"
          :is-t-v="true"
          v-model="selectedShows"
          label="телепередачи"
          item-text="name"
          item-value="program_id"
          :multiple="true"
          item-group="tvchannel"
          :show-select-all="true"
          :select-all-to-null="false"
          :isCity="false"
          :isTV="false"
          :parentClickable="true"
          :highlightParents="highlightParents"
          @children="selectChildren"
          @cubeDataClick="getReport"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="selectAllProgram()">
              <v-list-item-action>
                <v-icon>
                  {{
                    all_programs
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ all_programs ? "Очистить" : "Выбрать все" }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </mh-drop-down>
      </v-col>
    </v-row>
    <v-divider
      :class="
        'ml-4 mr-4 ' +
        ($vuetify.breakpoint.mdAndDown ? 'mt-n5 mb-4 ' : 'mt-3 mb-3 ')
      "
    ></v-divider>
    <v-row class="ma-1 pr-2 pl-2 mb-4">
      <v-col cols="12" class="pb-0 pt-0 pr-1 pl-1 text-right">
        <v-btn
          elevation="0"
          color="primary"
          class="mh-primary-border"
          @click="getReport"
        >
          Построить
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss"></style>
<script>
import axios from "axios";
import MainCalendar from "@/components/MainCalendar.vue";
import Utils from "../services/utils";
import MhDropDown from "@/components/MhTvDropDown.vue";
import moment from "moment";
export default {
  name: "ContentProducersFilters",
  components: { MainCalendar, MhDropDown },
  props: {
    startIndicator: {
      type: String,
      default: "Audience",
    },
  },
  async mounted() {
    this.indicator = this.startIndicator;
    await this.getShows();
  },
  computed: {
    indicatorsList() {
      const page = { title: "", type: "", many_channel: false };
      return Utils.indicatorsList(
        "mh",
        page,
        this.$store.getters.StateUser.indicators
      );
    },
    isMobile() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
  },
  methods: {
    selectChildren(parent) {
      let operation = "add";
      if (this.selectedShows === null) this.selectedShows = [];
      const affectedShowsIds = this.shows
        .filter((item) => item.tvchannel == parent)
        .map((item) => item.program_id);
      const alreadyAddedShowsIds = affectedShowsIds.filter((item) =>
        this.selectedShows.includes(item)
      );
      if (alreadyAddedShowsIds.length == affectedShowsIds.length)
        operation = "remove";
      setTimeout(() => {
        if (this.selectedShows === null) this.selectedShows = [];
        affectedShowsIds.forEach((item) => {
          if (operation == "add") {
            if (!this.selectedShows.includes(item))
              this.selectedShows.push(item);
            if (!this.highlightParents.includes(parent))
              this.highlightParents.push(parent);
          }
          if (operation == "remove") {
            this.selectedShows = this.selectedShows.filter(
              (item) => !alreadyAddedShowsIds.includes(item)
            );
            this.highlightParents = this.highlightParents.filter(
              (item) => item != parent
            );
          }
        });
      });
    },
    async selectAllProgram(e) {
      this.all_programs = e; //!this.all_programs;

      if (this.all_programs) {
        this.selectedShows = [];
        this.shows.forEach((sh) => {
          this.selectedShows.push(sh.program_id);
        });
      } else {
        this.selectedShows = [];
      }
    },
    selectMyPrograms() {
      this.selectedShows = this.shows
        .filter((item) => item.my == 1)
        .map((item) => item.program_id);
    },
    async getReport() {
      let shows;
      if (!this.selectedShows.length) {
        shows = [...this.shows];
      } else {
        shows = this.shows.filter((item) =>
          this.selectedShows.includes(item.program_id)
        );
      }
      const myShows = shows
        .filter((item) => item.my == 1)
        .map((item) => item.program_id);
      const range = this.range;
      range.startDate = moment(this.range.start).format("YYYY-MM-DD");
      range.endDate = moment(this.range.end).format("YYYY-MM-DD");
      range.startTime = moment(this.range.start).format("HH:mm");
      range.endTime = moment(this.range.end).format("HH:mm");
      const data = {
        indicator: this.indicator,
        shows,
        myShows,
        range: this.range,
      };
      this.$emit("cubeDataClick", data);
    },
    calendarHandler(range) {
      this.range = range;
    },
    async getShows() {
      let res = await axios.post("/params/developers_progs", {});
      if (res.data && Array.isArray(res.data)) {
        this.shows = [...res.data];
        //await this.selectAllProgram(this.all_programs);
        this.selectMyPrograms();
      }
    },
    filterAutoComplete(item, queryText, itemText) {
      return Utils.filterAutoComplete(item, queryText, itemText);
    },
  },
  data: () => ({
    shows: [], // все передачи селектора
    selectedShows: [], //выбранные передачи
    highlightParents: [], //Массив занчени названий разделов для подсветки
    all_programs: true,
    range: null,
    indicator: "Audience",
  }),
};
</script>
