<template>
  <v-container
    :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'"
    ref="content_card"
    id="content_card"
  >
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <content-producers-filters
          @cubeDataClick="getReport"
          startIndicator="TVR"
        />
        <v-card
          outlined
          class="mt-2"
          v-if="chart_loading === false"
          id="highcharts_png"
          ><v-card-title
            class="text-lg-h5 text-md-h6 justify-space-between pb-0 flex-row"
          >
            <v-col
              class="col-12 col-md-4 text-center text-md-left ml-0 pl-0 pb-0"
              style="word-break: break-word"
            >
              {{ chartName }}
            </v-col>
            <v-col
              class="col-12 col-md-8 text-center text-md-right"
              id="chartControls"
              data-html2canvas-ignore
            >
              <v-menu offset-y open-on-hover max-width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    elevation="0"
                    outlined
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :class="isMobile ? 'mr-2 upTo5' : 'mr-2 upTo2'"
                    style="
                      border-radius: 4px !important;
                      height: 36px;
                      width: 36px;
                    "
                    @click="toggleAverageValues"
                    ><v-icon>mdi-feature-search</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-card-text
                    >Показать средние значения и оклонения</v-card-text
                  >
                </v-card>
              </v-menu>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    elevation="0"
                    class="mh-secondary-border ml-2"
                    :class="
                      isMobile
                        ? 'mh-secondary-border ml-2 upTo5'
                        : 'mh-secondary-border ml-2 upTo2'
                    "
                    style="text-transform: none !important"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-download</v-icon>Скачать</v-btn
                  >
                </template>
                <v-list dense>
                  <v-list-item v-if="false" dense link @click="toExcel">
                    <v-list-item-title>Таблица (xlsx)</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense link @click="saveChartToPng">
                    <v-list-item-title>Изображение (png)</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-col class="ml-0 pl-0">
              <div class="chartTitle">{{ chartTitle }}</div>
              <div id="highchartDiv" v-if="chart">
                <highcharts :options="chartOptions" />
                <div class="customLegend" v-if="false">
                  <div v-for="tvId of tvIds" :key="tvId">
                    <span :style="`color: ${getTvIdColor(tvId)}`"
                      >&nbsp;●&nbsp;</span
                    >{{ tvNames[tvId] }}
                  </div>
                </div>
                <div class="customLegend" v-if="true">
                  <div v-for="tvshow of uniqShows" :key="tvshow.subprogram_id">
                    <span :style="`color: ${programsColors[tvshow.program_id]}`"
                      >&nbsp;●&nbsp;</span
                    >{{ tvshow.name }}
                  </div>
                </div>
                <v-row class="mt-0 pt-0" v-if="!selectedProg">
                  <v-col cols="12">
                    <span class="chartHelper" data-html2canvas-ignore>
                      <v-icon small color="#FFA91B" class="pr-1"
                        >mdi-lightbulb</v-icon
                      >Нажмите на столбик телеканала, чтобы посмотреть
                      детализацию
                    </span>
                  </v-col>
                </v-row>
                <div v-if="selectedProg">
                  <div>
                    <v-row>
                      <v-col cols="12" class="popupBlockTitle mb-0 pb-0"
                        >Название</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockContent mt-0 pt-0 mb-4 pb-0"
                        >{{ selectedProg.progName }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockContent mt-0 pt-0 mb-4 pb-0 font-weight-regular"
                        style="font-size: 14px"
                      >
                        {{ drawProgDates(selectedProg) }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockTitle mt-0 pt-0 mb-0 pb-1"
                        >{{ indicatorName }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockContentBig mt-0 pt-0 mb-4 pb-0"
                        >{{ selectedProg.y }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockTitle mt-0 pt-0 mb-0 pb-0"
                        >Канал</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockContent mt-0 pt-0 mb-4 pb-0"
                        >{{ tvNames[selectedProg.tvId] }}</v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        class="popupBlockTitle mt-0 pt-0 mb-0 pb-0"
                        >Описание программы</v-col
                      >
                    </v-row>
                    <v-row class="mt-0 pt-0">
                      <v-col
                        cols="12"
                        class="popupBlockContentSmall"
                        style="max-width: 100%"
                      >
                        {{ selectedProg.short_text }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider class="mt-4" />
                  <div
                    v-if="indicator != 'Reach' && indicator != 'ReachPercent'"
                  >
                    <div
                      class="text-lg-h5 text-md-h6 mt-4 black--text font-weight-bold"
                    >
                      Поминутная детализация телепередачи
                    </div>
                    <div class="chartTitle mt-4" v-if="chart2ready">
                      {{ chartTitle }}
                    </div>
                    <div class="chartSubtitle mt-n5" v-if="chart2ready">
                      <span
                        v-if="hasVideoChannel"
                        style="
                          font-size: 1.1em;
                          font-family: 'Material Design Icons';
                        "
                        >&#xF0567;</span
                      >
                      <span
                        v-if="!hasVideoChannel"
                        style="
                          font-size: 1.1em;
                          font-family: 'Material Design Icons';
                        "
                        >&#xF0568;</span
                      >
                      {{ chartSubtitle }}
                    </div>
                    <highcharts
                      :options="chartOptionsHour"
                      v-if="chart2ready"
                      ref="chartHour"
                    />
                    <v-divider class="mt-4" />
                  </div>
                  <div
                    class="text-lg-h5 text-md-h6 mt-4 black--text font-weight-bold"
                  >
                    Приток-отток зрителей
                  </div>
                  <div class="chartTitle mt-4" v-if="chart2ready">
                    {{ chartTitle }}
                  </div>
                  <div class="chartSubtitle mt-n5" v-if="chart2ready">
                    <span
                      v-if="hasVideoChannel"
                      style="
                        font-size: 1.1em;
                        font-family: 'Material Design Icons';
                      "
                      >&#xF0567;</span
                    >
                    <span
                      v-if="!hasVideoChannel"
                      style="
                        font-size: 1.1em;
                        font-family: 'Material Design Icons';
                      "
                      >&#xF0568;</span
                    >
                    {{ chartSubtitle }}
                  </div>
                  <highcharts
                    :options="chartOptionsIncomes"
                    v-if="chart3ready"
                    ref="chartIncomes"
                  />
                </div>
              </div>
              <div v-if="!chart">Данных не найдено.</div>
            </v-col>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mt-2"
          height="400"
          v-if="chart_loading === true"
        >
          <base-loader></base-loader>
        </v-card>
        <v-row
          justify="center"
          class="mt-12"
          style="margin-left: 0; margin-right: 0"
          v-if="chart_loading === null"
        >
          <pre-report-mount />
        </v-row>
      </v-col>
    </v-row>
    <video-player
      :shown="videoPlayerOn"
      :dt="videoPlayerDt"
      :videoChannelId="videoPlayerChannel"
      :videoChannelName="videoPlayerChannelName"
      :valueName="videoPlayerValueName"
      :values="videoPlayerValues"
      :program="videoPlayerProgram"
      @close="videoPlayerOn = false"
    />
  </v-container>
</template>
<style scoped>
.customLegend {
  font-size: 12px;
  color: rgb(51, 51, 51);
  font-weight: 700;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
    sans-serif;
  text-align: center;
}
.customLegend > div {
  display: inline-block;
  margin-left: 12px;
}
.customLegend > div > span {
  font-size: 1.4em;
}
</style>
<script>
import html2canvas from "html2canvas";
import moment from "moment";
import { Chart } from "highcharts-vue";
import axios from "axios";
import ContentProducersFilters from "@/components/ContentProducersFilters.vue";
import BaseLoader from "@/components/BaseLoader.vue";
import PreReportMount from "@/components/PreReportMount.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { CONSTS } from "../services/constants.js";
import Utils from "../services/utils";

export default {
  mounted() {
    if (!this.hasPriv) this.$router.push("/");
    window.addEventListener("resize", this.handleResize, true);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: {
    ContentProducersFilters,
    BaseLoader,
    VideoPlayer,
    PreReportMount,
    highcharts: Chart,
  },
  data: () => ({
    reportTime: null,
    avgValue: 0,
    showAvarages: true,
    contentWidth: null,
    reportResults: {},
    chart_loading: null,
    indicator: null,
    range: null,
    programsColors: {}, // присвоенные передачам цвета
    extraTime: CONSTS.CONTENT_PRODUCERS_EXTRATIME, // доп время слева и справа для 2 и 3 графиков
    myShows: [], // передачи помеченные для пользователя своими
    chart: null, // первый график (накопление данных)
    chartProg: null, // первый график
    chartHour: null, // минутный график
    chartIncomes: null, // минутный график
    tvNames: {}, // соответствие tvId->tvName
    tvIds: [], // пришедшие в отчете tvId
    channelsColors: {}, // присвоенные tvId цвета
    selectedProg: null, // Данные о выбранной передаче для построенения 2-3 графиков
    chart2ready: false, // данные для 2 графика подготовлены
    chart3ready: false, // данные для 3 графика подготовлены
    videoPlayerOn: false, // если true - открыт диалог проигрывания видео
    videoPlayerChannel: null,
    videoPlayerChannelName: null,
    videoPlayerValueName: null, // название отображаемого показтеля
    videoPlayerDt: null,
    videoPlayerProgram: null, // расписание передач
    videoPlayerValues: null, // помнутные значения выбранного показателя
  }),
  computed: {
    hasVideoChannel() {
      return !!this.chartHour.charts[0].series[0].video_channel_id;
    },
    uniqShows() {
      const uniqs = [];
      const uniqsIds = new Set();
      if (!this.chart) return uniqs;
      for (const tvshow of this.chart.programs) {
        if (!uniqsIds.has(tvshow.program_id)) {
          uniqsIds.add(tvshow.program_id);
          uniqs.push(tvshow);
        }
      }
      return uniqs;
    },
    filename() {
      const city = { name: "Москва" };
      const channels = this.selectedProg
        ? [this.tvNames[this.selectedProg.tvId]]
        : [];
      const shows = this.selectedProg ? this.chartProg.programs : [];
      const filename = Utils.createFilename({
        range: this.range,
        cities: city,
        channels: channels,
        shows: shows,
        indicator: this.indicatorName,
        reportTime: this.reportTime,
      });
      return filename;
    },
    hasPriv() {
      return !!this.$store.getters.StateUser.privs[
        CONSTS.PRIVS_KEYS.section_content_producers
      ];
    },
    isMobile() {
      return !this.$vuetify.breakpoint.mdAndUp;
    },
    chartName() {
      return Utils.indicatorName(this.indicator);
    },
    indicatorName() {
      return Utils.indicatorName(this.indicator);
    },
    chartTitle() {
      const start = moment(this.range.start).locale("ru").format("DD MMM YYYY");
      const end = moment(this.range.end).locale("ru").format("DD MMM YYYY");
      const startTime = moment(this.range.start).locale("ru").format("HH:mm");
      const endTime = moment(this.range.end).locale("ru").format("HH:mm");
      const weekdays = this.drawWeekdays;
      return `Москва, ${start}, ${startTime} - ${end}, ${endTime} ${weekdays}`;
    },
    chartSubtitle() {
      const start = moment(this.selectedProg.sdate)
        .locale("ru")
        .format("DD MMM YYYY");
      const end = moment(this.selectedProg.edate)
        .locale("ru")
        .format("DD MMM YYYY");
      const startTime = moment(this.selectedProg.sdate)
        .locale("ru")
        .format("HH:mm");
      const endTime = moment(this.selectedProg.edate)
        .locale("ru")
        .format("HH:mm");
      const tvName = this.tvNames[this.selectedProg.tvId];
      return `${tvName}, ${start}, ${startTime} - ${end}, ${endTime}`;
    },
    drawWeekdays() {
      return Utils.drawWeekdays(this.range.weekdays);
    },
    chartSubTitle() {
      return "";
    },
    chartOptions() {
      const that = this;
      const chartHeight = 500;
      const data = this.chartProg;
      if (!data) return { title: "По заданному запросу данных не найдено" };
      const categories = data.categories.map((item) =>
        item.replace(" |  | ", " | ")
      );
      if (!categories.length)
        return { title: "По заданному запросу данных не найдено" };

      const chartMax = Math.max(...data.series[1].data.map((s) => s.y)); //series 0 now is bg
      for (const item of data.series[0].data) item.color = "rgba(0,0,0,0)";
      const bgWidth = ((this.contentWidth - 50) / categories.length) | 0;
      //console.log(bgWidth)
      data.series[0].pointWidth = bgWidth - 1;

      if (this.selectedProg) {
        // цвета колонок по названиям каналов - возможно веренем!!
        //data.series[0].data[this.selectedProg.chartIndex].color = CONSTS.TV_SPECIAL_COLORS[this.selectedProg.tvId][1];
        data.series[0].data[this.selectedProg.chartIndex].color =
          CONSTS.TOP20_OPEN_CHANNEL_BG_COLOR;
      }

      data.programs.forEach((show, i) => {
        data.series[1].data[i].color = this.programsColors[show.program_id];
        if (this.myShows.includes(show.program_id)) {
          // цвета колонок по названиям каналов - возможно веренем!!
          //data.series[0].data[i].color = CONSTS.TV_SPECIAL_COLORS[show.tvid][2];
          data.series[0].data[i].color = this.programsColors[
            show.program_id
          ].replace("1)", ".1)");
        }
      });

      //console.log(this.selectedProg, data.series, this.programsColors);
      const chartOptions = {
        id: "mainChart",
        animation: false,
        chart: {
          marginLeft: 50,
          animation: false,
          type: "column",
          height: chartHeight,
        },
        legend: { enabled: false },
        title: { text: "" },
        subtitle: { text: "" },
        xAxis: {
          crosshair: true,
          categories,
          labels: {
            y: 20,
            x: 0,
            align: null,
            rotation: data.categories.length < 3 ? 0 : -45,
            style: { fontSize: "12px" },
            formatter: function () {
              const progName =
                this.chart.xAxis[0].series[1].userOptions.data[this.pos].prog
                  .name;
              const labelColor =
                progName != that.myProg ? "rgba(0,0,0,.58)" : "rgba(0,0,0,.78)";
              const day = that.chartProg.categoriesDays[this.pos];
              const data = this.value.split("|");
              let labelStr = `<span style='color:${labelColor};'>${data[0]}</span>`;
              if (day) {
                labelStr = `<i style="text-decoration:underline; font-size: .9em">${day}</i> ${labelStr}`;
              }
              return labelStr;
            },
          },
        },
        yAxis: {
          min: 0,
          max: chartMax * 1.02,
          // opposite: false,
          // reversedStacks: false,
          title: { text: this.indicatorName },
          plotLines: this.showAvarages
            ? [
                {
                  color: "#000",
                  value: this.avgValue,
                  width: 1,
                  dashStyle: "Dash",
                  zIndex: 4,
                },
              ]
            : null,
        },
        tooltip: {
          useHTML: true,
          formatter() {
            that;
            let [prog, subprog, dt] = this.x.split("|");
            if (!dt) {
              // костыль с разбором формата данных
              dt = subprog;
              subprog = "";
            }
            const [sdt, edt] = dt.split(" - ");
            const sdate = sdt.substring(1, 11);
            const edate = edt.substring(0, 10);
            let dateStr = "";
            if (sdate == edate) {
              dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY HH:mm"
              )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
            } else {
              dateStr = `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY HH:mm"
              )} - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                "DD.MM.YYYY HH:mm"
              )}`;
            }
            let str = `                
                ${dateStr}<br>
                ${that.indicatorName}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b>${this.point.tvName}</b><br>
                <b>${prog}</b><br>
                ${subprog}
                `;
            return str;
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              inside: false,
              useHtml: true,
              enabled: this.showAvarages,
              crop: false,
              overflow: "none",
              style: {
                textOutline: 0,
              },
              formatter: function () {
                let resultHtml;
                const result =
                  Math.round(
                    ((this.y - that.avgValue) / that.avgValue) * 10000
                  ) / 100;
                if (result > 0)
                  resultHtml =
                    '<span style="color: green; text-align: center; font-size:10px;">+' +
                    result +
                    "%</span>";
                if (result < 0)
                  resultHtml =
                    '<span style="color: red; text-align: center; font-size:10px;"> ' +
                    result +
                    "%</span>";
                if (result == 0)
                  resultHtml =
                    '<span style="color: black">' + result + "</span>";
                return resultHtml;
              },
            },
          },
          series: {
            turboThreshold: Infinity,
            states: {
              inactive: {
                opacity: 1,
              },
            },
            stacking: false,
            point: {
              events: {
                click: function () {
                  const sdate = this.prog.start_loc;
                  const edate = this.prog.stop_loc;
                  const sdateExtra = moment(sdate, "YYYY-MM-DD HH:mm:ss")
                    .subtract(that.extraTime, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss");
                  const edateExtra = moment(edate, "YYYY-MM-DD HH:mm:ss")
                    .add(that.extraTime, "minutes")
                    .format("YYYY-MM-DD HH:mm:ss");
                  that.selectedProg = {
                    id: this.prog.program_id,
                    chartIndex: this.x,
                    y: this.y,
                    tvId: this.tvId,
                    progName: this.prog.name,
                    progId: this.prog.subprogram_id,
                    sdate,
                    edate,
                    sdateExtra,
                    edateExtra,
                    short_text: this.prog.short_text,
                  };
                  that.getMinutesChart();
                  that.getIncomesChart();
                },
              },
            },
          },
        },
        series: data.series,
      };

      return chartOptions;
    },
    chartOptionsHour() {
      const that = this;
      const data = this.chartHour?.charts[0];
      if (!data) return {};

      let ticks = 12;
      let mult = (data.categories.length / 8) | 0;
      if (data.categories.length <= 15) {
        ticks = 15;
        mult = 1;
      }
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult);
      let chartMax = 0;
      for (const seriesData of data.series) {
        const seriesMax = Math.max(...seriesData.data.map((s) => s.y));
        if (seriesMax > chartMax) chartMax = seriesMax;
      }

      const chart = {
        id: "minuteChart",
        plotBands: [],
        chart: {
          zoomType: "x",
          marginRight: 15,
          type: "areaspline",
          events: {
            click: function (e) {
              if (e.srcElement.firstChild) return; // reset zoom pressed
              const data = that.chartHour.charts[0];
              const videoChannelId = data.series[0].video_channel_id;
              const channelName = data.series[0].name;
              const point = parseInt(e.xAxis[0].value);
              const programs = data.programs;
              const values = that.prepareValuesData(
                data.categories,
                data.series[0]
              );
              const dt = moment(data.categories[point])
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
              that.playPoint(
                videoChannelId,
                dt,
                channelName,
                that.indicatorName,
                programs,
                values
              );
            },
          },
          height: this.heightChart,
        },
        title: { text: "" },
        subtitle: { text: "" },
        legend: { enabled: false },
        colors: CONSTS.CHART_COLORS,
        xAxis: {
          tickPositions,
          tickWidth: 1,
          tickmarkPlacement: "on",
          min: 0.5,
          max: data.categories.length - 1.5,
          startOnTick: false,
          endOnTick: false,
          minPadding: 0,
          maxPadding: 0,
          align: "left",
          categories: data.categories,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function () {
              let value = this.value;
              let h, m;
              if (value.toString().length == 5) {
                h = value.substring(0, 2);
                m = value.substring(3, 5);
              } else {
                value = moment(value).utc().format("YYYY-MM-DD HH:mm");
                h = value.substring(11, 13);
                m = value.substring(14, 16);
              }

              if (this.isFirst) {
                return `
                <div style="padding-top: 5px">
                  <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                  <b style="font-size: 1.1em;">${h}</b>
                  <br>

                  <span style="padding-right: 6px; margin-left: -20px;">мин\u00a0</span>${m}
                </div>

                `;
              }
              return `
                <div style="padding-top: 4px; margin-left: 10px;">

                <span style=""><b style="font-size: 1.1em;">${h}</b></span>
                <br>
                <span style="">${m}</span>

                </div>
              `;
            },
          },
        },
        yAxis: {
          min: 0,
          max: chartMax,
          reversedStacks: false,
          title: { text: this.indicatorName },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          areaspline: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, CONSTS.CHART_COLORS[0]],
                [1, CONSTS.CHART_COLORS[0].replace("1)", "0)")],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
          series: {
            stickyTracking: false,
            inside: false,
            states: {
              inactive: {
                opacity: 1,
              },
            },
            events: {
              click: function (event) {
                const data = that.chartHour.charts[0];
                const videoChannelId = data.series[0].video_channel_id;
                const channelName = data.series[0].name;
                const programs = data.programs;
                const values = that.prepareValuesData(
                  data.categories,
                  data.series[0]
                );
                const dt = moment(event.point.category)
                  .utc()
                  .format("YYYY-MM-DD HH:mm:ss");
                that.playPoint(
                  videoChannelId,
                  dt,
                  channelName,
                  that.indicatorName,
                  programs,
                  values
                );
              },
            },
            turboThreshold: 3600 * 24,
            marker: {
              radius: 1,
              enabled: false,
            },
          },
        },
        tooltip: {
          useHTML: true,
          formatter(e) {
            function fillProgData(dt) {
              const tvId = that.selectedProg.tvId;
              const prog = data.programs.find(
                (item) =>
                  item.tvid == tvId &&
                  item.start_loc <= dt &&
                  item.stop_loc >= dt
              );
              return prog;
            }

            let hhmm = this.x;
            let prog = null;
            hhmm = moment(this.x).utc().format("HH:mm");
            const dt = moment(this.x).utc().format("YYYY-MM-DD HH:mm:ss");
            prog = fillProgData(dt, 0, e.chart.options.id);
            if (prog) {
              return `
                ${hhmm}<br>
                ${that.indicatorName}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b> ${this.series.name}</b><br>
                <b>${prog.title}</b><br>
                ${prog.subtitle}
              `;
            }
            return `
                ${hhmm}<br>
                <b> ${this.series.name}</b>: ${this.y}<br>
                `;
          },
        },
        series: data.series,
      };
      return chart;
    },
    chartOptionsIncomes() {
      const that = this;
      const data = this.chartIncomes?.charts[0];
      if (!data) return {};
      let ticks = 12;
      let mult = (data.categories.length / 8) | 0;
      if (data.categories.length <= 15) {
        ticks = 15;
        mult = 1;
      }
      if (this.isMobile) {
        mult *= 4;
        ticks = 6;
      }
      const tickPositions = new Array(ticks).fill().map((item, i) => i * mult);

      const chart = {
        id: "incomesChart",
        chart: {
          zoomType: "x",
          type: "column",
          events: {},
          height: 400,
        },
        title: { text: "" },
        subtitle: { text: "" },
        colors: CONSTS.CHART_INOUT_COLORS,
        xAxis: {
          tickPositions,
          tickWidth: 1,
          tickmarkPlacement: "on",
          min: 0.5,
          max: data.categories.length - 1.5,
          startOnTick: false,
          endOnTick: false,
          categories: data.categories,
          labels: {
            rotation: 0,
            useHTML: true,
            formatter: function () {
              const value = this.value;
              if (this.isFirst) {
                return `
                <div style="padding-top: 5px">
                  <span style="padding-right: 5px; margin-left: -20px; font-size: 1.1em; font-weight: bold;">час\u00a0</span>
                  <b style="font-size: 1.1em;">${value?.substring(11, 13)}</b>
                  <br>
                  <span style="padding-right: 6px; margin-left: -20px;">мин\u00a0</span>${value?.substring(
                    14,
                    16
                  )}
                </div>  
                `;
              }
              return `
                <div style="padding-top: 4px; margin-left: 10px;">
                <span style=""><b style="font-size: 1.1em;">${value?.substring(
                  11,
                  13
                )}</b></span>
                <br>
                <span style="">${value?.substring(14, 16)}</span>  
                </div>
              `;
            },
          },
        },
        yAxis: {
          reversedStacks: false,
          title: { text: "Проценты" },
          max: 20,
          min: -20,
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "#808080",
            },
          ],
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          column: {
            grouping: false,
          },
          series: {
            events: {
              click: function (event) {
                const data = that.chartIncomes?.charts[0];
                const videoChannelId = data.series[0].video_channel_id;
                const channelName = data.series[0].subtitle ?? null;
                const programs = data.programs;
                //костыль связан со сложным преобразованием дат из исходного формата
                const categories = data.categories.map(
                  (item) => moment(`${item}Z`).unix() * 1000
                );
                const values = that.prepareValuesData(
                  categories,
                  data.series[0],
                  data.series[1]
                );
                const dt = event.srcElement.point.category;
                that.playPoint(
                  videoChannelId,
                  dt,
                  channelName,
                  "inout",
                  programs,
                  values
                );
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
            dataLabels: { enabled: false },
            turboThreshold: 0,
          },
          area: {
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          formatter(e) {
            function fillProgData(dt) {
              const tvId = that.selectedProg.tvId;
              const prog = data.programs.find(
                (item) =>
                  item.tvid == tvId &&
                  item.start_loc <= dt &&
                  item.stop_loc >= dt
              );
              return prog;
            }

            const hhmm = moment(this.x, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
            const dt = this.x;
            let prog = null;
            prog = fillProgData(dt, 0, e.chart.options.id);
            if (prog) {
              return `
                ${hhmm}<br>
                ${this.series.name}: <b>${this.y}</b>
                <div style="width: 100%; height: 1px; background-color: #757575; margin: 4px 0 4px 0"></div>
                <b>${that.tvNames[that.selectedProg.tvId]}</b><br>
                <b>${prog.title}</b><br>
                ${prog.subtitle}
              `;
            }
            return `
                ${hhmm}<br>
                <b> ${this.series.name}</b>: ${this.y}<br>
                `;
          },
          positioner: function (labelWidth, labelHeight, point) {
            const maxWidth = that.contentWidth + 48;
            let tooltipX = point.plotX;
            let tooltipY = point.plotY;
            if (tooltipY + labelHeight > 550) tooltipY = 550 - labelHeight;
            if (tooltipX > maxWidth - labelWidth)
              tooltipX = maxWidth - labelWidth;
            return {
              x: tooltipX,
              y: tooltipY,
            };
          },
        },
        series: data.series,
      };
      return chart;
    },
  },
  methods: {
    handleResize() {
      this.contentWidth = document.getElementById("content_card").clientWidth;
      this.contentWidth = this.contentWidth - 70 - 20; // вычитаем отсупы внутри и снаружи графика
    },
    repaintBands() {
      const plotBands = [
        {
          from: 0,
          to: this.extraTime,
          color: CONSTS.CONTENT_PRODUCERS_EXTRATIME_COLOR,
        },
        {
          from: this.extraTime,
          to: this.chartIncomes.charts[0].categories.length - this.extraTime,
          color: CONSTS.CONTENT_PRODUCERS_SHOW_COLOR,
        },
        {
          from: this.chartIncomes.charts[0].categories.length - this.extraTime,
          to: this.chartIncomes.charts[0].categories.length - 1,
          color: CONSTS.CONTENT_PRODUCERS_EXTRATIME_COLOR,
        },
      ];

      if (this.$refs.chartHour && this.$refs.chartHour.chart) {
        this.$refs.chartHour.chart.xAxis[0].update({ plotBands });
      }
      if (this.$refs.chartIncomes && this.$refs.chartIncomes.chart) {
        this.$refs.chartIncomes.chart.xAxis[0].update({ plotBands });
      }
    },
    prepareValuesData(categories, series, series2 = null) {
      const values = {};
      for (let i = 0; i < categories.length; i++) {
        const key = moment(categories[i]).utc().format("YYYY-MM-DD HH:mm");
        const newValues = [series.data[i].y];
        if (series2) {
          // приходы-уходы - 2 значения
          newValues.push(series2.data[i].y);
        }
        values[key] = newValues;
      }
      return values;
    },
    playPoint(videoChannelId, dt, channelName, valueName, programs, values) {
      console.log(
        `Play: ${videoChannelId} (${channelName}) time: ${dt} ${this.videoPlayerOn}`
      );

      if (this.videoPlayerOn) {
        this.videoPlayerOn = false;
        return setTimeout(() =>
          this.playPoint(
            videoChannelId,
            dt,
            channelName,
            valueName,
            programs,
            values
          )
        );
      }
      this.videoPlayerChannel = videoChannelId;
      this.videoPlayerChannelName = channelName;
      this.videoPlayerValueName = valueName;
      this.videoPlayerDt = dt;
      this.videoPlayerProgram = programs;
      this.videoPlayerValues = values;
      this.videoPlayerOn = true;
    },
    toggleAverageValues() {
      this.showAvarages = !this.showAvarages;
    },
    getTvIdColor(tvId) {
      return CONSTS.TV_SPECIAL_COLORS[tvId][0];
    },
    async saveChartToPng() {
      const canvas = await html2canvas(
        document.querySelector(`#highcharts_png`)
      );
      Utils.saveAs(canvas.toDataURL(), `${this.filename}.png`);
    },
    async getMinutesChart() {
      if (this.indicator == "Reach" || this.indicator == "ReachPercent") return;

      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      const specificReportProperties = {
        requestedReport: "content_producers",
        message: "Производителям контента", // если задан - пишем под названием отчета
      };
      const reqData = new FormData();
      //this.selectedProg.sdate="2022-09-30 23:00:00"
      reqData.append("requestedReport", "tvchannels");
      reqData.append("specificReport", true);
      reqData.append(
        "specificReportProperties",
        JSON.stringify(specificReportProperties)
      );
      reqData.append("geo", 178);
      reqData.append("helper", "mh");
      reqData.append("manytv", false);
      reqData.append("source_id", 1);
      reqData.append("detalisation", 1);
      reqData.append("aggregate", "Day");
      reqData.append("report_type", "hour");
      reqData.append("indicator", this.indicator);
      reqData.append("tv", this.selectedProg.tvId);
      reqData.append(
        "startDate",
        this.selectedProg.sdateExtra.substring(0, 10)
      );
      reqData.append("endDate", this.selectedProg.edateExtra.substring(0, 10));
      reqData.append(
        "startTime",
        this.selectedProg.sdateExtra.substring(11, 14) + "00"
      );
      reqData.append("endTime", this.selectedProg.edateExtra.substring(11, 16));
      reqData.append("through", true);
      reqData.append("weekdays", "1,2,3,4,5,6,7");
      const res = await axios
        .post("reports/cubedata", reqData, {})
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
        });

      if (this.isStoped) return;

      if (res.data.charts.length > 1) {
        // объединяем данные при переходе через сутки
        const chartsCategories = res.data.charts.map(
          (chart) => chart.categories
        );
        res.data.charts[0].categories = [].concat(...chartsCategories);
        const chartsSeries = res.data.charts.map(
          (chart) => chart.series[0].data
        );
        res.data.charts[0].series[0].data = [].concat(...chartsSeries);
      }

      let startIndex = 0; // ищем где начинается передача т.к. на входе данные по часу
      let stopIndex = 0; // ищем где заканчивается передача т.к. на входе данные по часу

      const startMinute = +this.selectedProg.sdateExtra.substring(14, 16); // первая минута передачи
      const stopHHMM = this.selectedProg.edateExtra.substring(11, 16); // последняя минута передачи
      for (const item of res.data.charts[0].categories) {
        const catMinute = +item.substring(3, 5);
        if (catMinute >= startMinute) break;
        startIndex++;
      }
      for (let i = res.data.charts[0].categories.length - 1; i > 0; i--) {
        const hhmm = res.data.charts[0].categories[i];
        if (hhmm <= stopHHMM) {
          stopIndex = i;
          break;
        }
      }
      res.data.charts[0].series[0].data = res.data.charts[0].series[0].data
        .filter((item, i) => i >= startIndex)
        .filter((item, i) => i <= stopIndex - startIndex);
      res.data.charts[0].categories = res.data.charts[0].categories
        .filter((item, i) => i >= startIndex)
        .filter((item, i) => i <= stopIndex - startIndex)
        .map(
          (item) =>
            moment(
              `${res.data.charts[0].series[0].subtitle} ${item}:00Z`,
              "DD.MM.YYYY HH:mm:ssZ"
            ).unix() * 1000
        );

      this.chartHour = res.data;
      this.chart2ready = true;
      setTimeout(() => this.repaintBands());
    },

    async getIncomesChart() {
      this.chart3ready = false;
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      const specificReportProperties = {
        requestedReport: "content_producers",
        message: "Производителям контента", // если задан - пишем под названием отчета
      };
      const reqData = new FormData();
      reqData.append("requestedReport", "incomes");
      reqData.append("specificReport", true);
      reqData.append(
        "specificReportProperties",
        JSON.stringify(specificReportProperties)
      );
      reqData.append("geo", 178);
      reqData.append("helper", "mh");
      reqData.append("manytv", false);
      reqData.append("source_id", 1);
      reqData.append("detalisation", 1);
      reqData.append("aggregate", "Period");
      reqData.append("report_type", "period");
      reqData.append("indicator", "IncomesOutcomes");
      reqData.append("tv", this.selectedProg.tvId);
      // reqData.append("programIds", this.selectedProg.progId);
      reqData.append(
        "startDate",
        this.selectedProg.sdateExtra.substring(0, 10)
      );
      reqData.append("endDate", this.selectedProg.edateExtra.substring(0, 10));
      reqData.append(
        "startTime",
        this.selectedProg.sdateExtra.substring(11, 14) + "00"
      );
      reqData.append("endTime", this.selectedProg.edateExtra.substring(11, 16));
      //      reqData.append("startTime", "00:00");
      //      reqData.append("endTime", "23:59");
      reqData.append("through", true);
      reqData.append("weekdays", "1,2,3,4,5,6,7");
      const res = await axios
        .post("reports/cubedata", reqData, {})
        .catch(function () {
          this.chart_loading = null;
          this.isStoped = true;
        });

      if (this.isStoped) return;

      res.data.charts[0].categories = res.data.charts[0].categories.map(
        (item) => moment(`${item}:00Z`, "DD.MM.YYYY HH:mm:ssZ").unix() * 1000
      );

      res.data.charts[0].categories = res.data.charts[0].categories.map(
        (item) => moment(item).utc().format("YYYY-MM-DD HH:mm:ss")
      );

      let startIndex = 0; // ищем где начинается передача т.к. на входе данные по часу
      let stopIndex = 0; // ищем где заканчивается передача т.к. на входе данные по часу
      for (const item of res.data.charts[0].categories) {
        if (item >= this.selectedProg.sdateExtra) break;
        startIndex++;
      }
      for (let i = res.data.charts[0].categories.length - 1; i > 0; i--) {
        if (res.data.charts[0].categories[i] <= this.selectedProg.edateExtra) {
          stopIndex = i;
          break;
        }
      }

      res.data.charts[0].series[0].data = res.data.charts[0].series[0].data
        .filter((item, i) => i >= startIndex)
        .filter((item, i) => i <= stopIndex - startIndex);
      res.data.charts[0].series[1].data = res.data.charts[0].series[1].data
        .filter((item, i) => i >= startIndex)
        .filter((item, i) => i <= stopIndex - startIndex);
      res.data.charts[0].categories = res.data.charts[0].categories
        .filter((item, i) => i >= startIndex)
        .filter((item, i) => i <= stopIndex - startIndex);

      this.chartIncomes = res.data;
      this.chart3ready = true;
      setTimeout(() => this.repaintBands());
    },
    async getReport(data) {
      this.reportTime = moment().format("DD.MM.YYYY HH:mm:ss");
      const specificReportProperties = {
        requestedReport: "content_producers", // id оригинального отчета
        title: "Производителям контента", // если задан - пишем вместо названия отчета
        //  message: "Производителям контента", // если задан - пишем под названием отчета
        url: "content_producers", //если задан - редиректим по клику на эту страницу
        hidden: true, //если задан и true - не показываем в истории
      };

      this.chart = null;
      this.chartProg = null;
      this.tvIds = [];
      this.reportResults = {};
      this.programsColors = {};
      this.selectedProg = null;
      this.chart2ready = false;
      this.chart3ready = false;
      this.indicator = data.indicator;
      this.range = data.range;
      this.myShows = data.myShows;

      const that = this;
      const tvIdShows = {};
      for (const show of data.shows) {
        if (!(show.index_tv_id_child in tvIdShows))
          tvIdShows[show.index_tv_id_child] = [];
        tvIdShows[show.index_tv_id_child].push(+show.program_id);
      }
      for (const tvId in tvIdShows) {
        this.chart_loading = true;
        const CancelToken = axios.CancelToken;
        const reqData = new FormData();
        reqData.append("requestedReport", "tvshows");
        reqData.append("noQueue", true);
        reqData.append("specificReport", true);
        reqData.append(
          "specificReportProperties",
          JSON.stringify(specificReportProperties)
        );
        reqData.append("geo", 178);
        reqData.append("helper", "mh");
        reqData.append("manytv", false);
        reqData.append("source_id", 1);
        reqData.append("report_type", "period");
        reqData.append("useCoeff", "no");
        reqData.append("aggregate", "None");
        reqData.append("detalisation", 60);
        reqData.append("indicator", data.indicator);
        reqData.append("startDate", this.range.startDate);
        reqData.append("endDate", this.range.endDate);
        reqData.append("startTime", this.range.startTime);
        reqData.append("endTime", this.range.endTime);
        reqData.append("through", this.range.through);
        reqData.append("weekdays", this.range.weekdays);
        reqData.append("tv", tvId);
        reqData.append("programIds", tvIdShows[tvId].join(","));
        let res;
        try {
          res = await axios.post("reports/cubedata", reqData, {
            cancelToken: new CancelToken(function executor(c) {
              that.axiosCancel = c;
            }),
          });
        } catch (e) {
          this.chart_loading = null;
          this.isStoped = true;
        }
        if (this.isStoped) break;
        //this.reportResults[tvId] = res.data;
        if (res.data.charts[0].categories.length) {
          this.tvIds.push(tvId);
          this.tvNames[tvId] = res.data.charts[0].series[0].subtitle;
          this.channelsColors[tvId] =
            CONSTS.CHART_COLORS[this.tvIds.length - 1];
          if (tvId in CONSTS.TV_SPECIAL_COLORS)
            this.channelsColors[tvId] = CONSTS.TV_SPECIAL_COLORS[tvId][0];
          this.appendChartData(
            res.data.charts[0],
            tvId,
            res.data.charts[0].series[0].subtitle
          );
        }
      }

      this.chart_loading = false;
      console.log(this.tvIds);
      if (!this.tvIds.length) return;
      const colors = [...CONSTS.CHART_COLORS];
      for (const tvShow of this.chart.programs) {
        if (!(tvShow.program_id in this.programsColors)) {
          if (colors.length) {
            this.programsColors[tvShow.program_id] = colors.shift();
          } else {
            this.programsColors[tvShow.program_id] = CONSTS.CHART_COLORS[0];
          }
        }
      }
      //console.log(this.programsColors);

      let seriesTotal = 0;
      this.avgValue = 0;
      if (!this.chart.series[0].data.length) return;
      for (let i = 0; i < this.chart.series[0].data.length; i++) {
        seriesTotal += this.chart.series[0].data[i].y;
      }
      this.avgValue = seriesTotal / this.chart.series[0].data.length;

      this.chart.categoriesDays = this.getCategoriesDaysMarks(
        this.chart.categories
      );
      this.chartProg = JSON.parse(JSON.stringify(this.chart));
      this.handleResize();

      const chartMax = Math.max(
        ...this.chartProg.series[0].data.map((s) => s.y)
      );

      const showsNum = this.chartProg.series[0].data.length;
      //const bgWidth = ((this.contentWidth - 50) / showsNum) | 0;
      //let pointWidth = (bgWidth/1.6)|0;

      const bgSeries = new Array(showsNum)
        .fill()
        .map(() => ({ y: chartMax * 2, color: "rgba(0,0,0,0)" }));

      this.chartProg.series.unshift({
        // pointWidth: bgWidth,
        enableMouseTracking: false,
        showInLegend: false,
        grouping: false,
        data: bgSeries,
      });
    },
    appendChartData(data, tvId, tvName) {
      if (this.chart === null) {
        this.chart = data;
        this.chart.channels = [];
        for (let i = 0; i < data.categories.length; i++) {
          this.chart.channels.push(tvId);
          // цвета колонок по названиям каналов - возможно веренем!!
          //this.chart.series[0].data[i].color = this.channelsColors[tvId];
          this.chart.series[0].data[i].tvId = tvId;
          this.chart.series[0].data[i].tvName = tvName;
        }
        return;
      }

      for (let itemIndex = 0; itemIndex < data.categories.length; itemIndex++) {
        const dt = this.getDtFromCategoryName(data.categories[itemIndex]);
        for (
          let chartIndex = 0;
          chartIndex < this.chart.categories.length;
          chartIndex++
        ) {
          const chartDt = this.getDtFromCategoryName(
            this.chart.categories[chartIndex]
          );
          let chartDtNext = "9999-99-99 99:99:99";
          if (chartIndex < this.chart.categories.length - 1) {
            chartDtNext = this.getDtFromCategoryName(
              this.chart.categories[chartIndex + 1]
            );
          }
          if (dt >= chartDt && dt < chartDtNext) {
            // цвета колонок по названиям каналов - возможно веренем!!
            // data.series[0].data[itemIndex].color = this.channelsColors[tvId];
            data.series[0].data[itemIndex].tvName = tvName;
            data.series[0].data[itemIndex].tvId = tvId;
            this.chart.programs.splice(
              chartIndex + 1,
              0,
              data.programs[itemIndex]
            );
            this.chart.categories.splice(
              chartIndex + 1,
              0,
              data.categories[itemIndex]
            );
            this.chart.channels.splice(chartIndex + 1, 0, tvId);
            this.chart.series[0].data.splice(
              chartIndex + 1,
              0,
              data.series[0].data[itemIndex]
            );
            break;
          }
          if (dt < chartDt) {
            // цвета колонок по названиям каналов - возможно веренем!!
            //data.series[0].data[itemIndex].color = this.channelsColors[tvId];
            data.series[0].data[itemIndex].tvName = tvName;
            data.series[0].data[itemIndex].tvId = tvId;
            this.chart.programs.unshift(data.programs[itemIndex]);
            this.chart.categories.unshift(data.categories[itemIndex]);
            this.chart.channels.unshift(tvId);
            this.chart.series[0].data.unshift(data.series[0].data[itemIndex]);
            break;
          }
        }
      }
    },
    getTotalDays(categories) {
      let oldDt;
      let count = 0;
      for (const category of categories) {
        const dt = this.getDtFromCategoryName(category).substring(0, 10);
        if (dt != oldDt && dt >= this.range.startDate) {
          count++;
          oldDt = dt;
        }
      }
      return count;
    },
    getCategoriesDaysMarks(categories) {
      const daysCount = this.getTotalDays(categories);
      console.log(daysCount);
      let oldDt;
      const posData = [];
      let daysStep = 1;
      // if (daysCount > 7) daysStep = 2;

      let dayCount = 0;
      for (const category of categories) {
        const dt = this.getDtFromCategoryName(category).substring(0, 10);
        let newValue = null;
        if (dt != oldDt && dt >= this.range.startDate) {
          dayCount++;
          oldDt = dt;
          if (dayCount >= daysStep) {
            newValue = dt;
            dayCount = 0;
          }
        }
        posData.push(newValue);
      }
      return posData;
    },
    getDtFromCategoryName(str) {
      let [prog, subprog, dt] = str.split("|");
      if (!dt) {
        // костыль с разбором формата данных
        dt = subprog;
        subprog = "";
        prog;
      }
      return dt.substring(1, 20);
    },
    drawProgDates(prog) {
      const sdt = prog.sdate;
      const edt = prog.edate;

      const sdate = moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");
      const edate = moment(edt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY");

      if (sdate != edate) {
        return `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")}
               - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format(
                 "DD.MM.YYYY HH:mm"
               )}
              `;
      }

      return `${moment(sdt, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm")}
              - ${moment(edt, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}`;
    },
  },
  watch: {
    "$route.path": function () {
      console.log(this.$route.name);
    },
  },
};
</script>
